#testimonials{
    padding-bottom: 0;
}


.swiper{
    height: fit-content;
    padding-bottom: 12rem;
    padding-top: 1rem;
}


.swiper-slide{
    cursor: grab;
}

.testimonial__client{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}


.testimonial__client-avatar{
    width: 2.5rem;
    aspect-ratio: 1/1;
    border-radius: var(--border-radius-2);
    overflow: hidden;
    transition: var(--transition);
}

.swiper-pagination{
    margin-bottom: 8rem;
}
.swiper-paginatio-bullet{
    background: var(--color-primary);
}

/*MEDIA QUERIES TABLETS */
@media  screen and


/*MEDIA QUERIES phone */